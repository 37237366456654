import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ValidateBankAccount } from './Components'

export function Reklamace(props) {
  const { t } = useTranslation();
  const [error, setError] = useState(false);

  useEffect(() => {  
    //predvyplneni ceskyho cisla uctu
    if (props.data.objednavka.shop_country_code === 'cz' && props.data.objednavka.cislo_uctu && props.data.objednavka.kod_banky && !props.userInput.cislo_uctu_user) {
      let cislo_uctu_premier = props.data.objednavka.cislo_uctu + '/' + props.data.objednavka.kod_banky
      cislo_uctu_premier = {
        target: {
          name: 'cislo_uctu_user',
          value: cislo_uctu_premier
        }
      }
      props.handleUserInput(cislo_uctu_premier)
    }

    //predvyplneni IBAN
    if (props.data.objednavka.shop_country_code !== 'cz' && !props.userInput.iban_user) {
      let iban_premier = {
        target: {
          name: 'iban_user',
          value: props.data.objednavka.iban
        }
      }
      props.handleUserInput(iban_premier)
    }
  }, [])

  const checkAndMove = async () => {
    if (!props.userInput.duvod_vraceni_detail) {
      setError('duvod_missing')
    } else if (props.userInput.has_special_conditions && !props.userInput.special_conditions) {
      setError('special_conditions_missing')
    } else if (!props.userInput.cislo_uctu_user && props.data.objednavka.shop_country_code === 'cz' && !props.userInput.no_account_number) {
      setError('ucet_missing')
    } else if (!props.userInput.iban_user && props.data.objednavka.shop_country_code !== 'cz' && !props.userInput.no_account_number) {
      setError('iban_missing')
    } else if (props.data.objednavka.shop_country_code === 'cz' && !props.userInput.no_account_number) {
      let bank_account_valid = await ValidateBankAccount(props.userInput.cislo_uctu_user)
      if (!bank_account_valid) {
        setError('wrong_account_number')
      } else {
        props.link('contact')
      }
    } else {
      props.link('contact')
    }
  }

  return (    
    <div className="row">
      <div className="col-12">
        <h2>{t('Důvod reklamace')}</h2>

        <div className="row mb-3">
          <div className="col-11">
            <label>{t('Rozepište důvod reklamace')} <span className="text-danger">*</span></label>
            <textarea maxLength="500" className="form-control" rows="2" placeholder="..." name="duvod_vraceni_detail" value={props.userInput.duvod_vraceni_detail} onChange={props.handleUserInput}></textarea>
          </div>
        </div>

        {props.data.objednavka.shop_country_code === 'cz' &&
          <>
            <div className="row mb-3">
              <div className="col-11">
                <label>{t('Číslo účtu pro případné vrácení peněz')} <span className="text-danger">*</span></label>
                <input disabled={props.userInput.no_account_number} type="text" className="form-control" name="cislo_uctu_user" value={props.userInput.cislo_uctu_user} onChange={props.handleUserInput} placeholder="xxxxxx/xxxx" />
              </div>
            </div>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" value="" name="no_account_number" checked={props.userInput.no_account_number} onChange={props.handleUserInput} />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                {t('Bez čísla účtu')}
              </label>
            </div>
          </>
        }

        {props.data.objednavka.shop_country_code !== 'cz' &&
          <>
            <div className="row mb-3">
              <div className="col-11">
                <label>{t('IBAN číslo účtu pro případné vrácení peněz')} <span className="text-danger">*</span></label>
                <input disabled={props.userInput.no_account_number} type="text" className="form-control" name="iban_user" value={props.userInput.iban_user} onChange={props.handleUserInput} placeholder="XX000000000" />
              </div>
            </div>
            {props.data.objednavka.dni_od_data_vystaveni_faktury < 150 &&
              <div className="form-check">
                <input className="form-check-input" type="checkbox" value="" name="no_account_number" checked={props.userInput.no_account_number} onChange={props.handleUserInput} />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  {t('Bez čísla účtu')}
                </label>
              </div>
            }
          </>
        }
        
        <div className="row mb-3">
          <div className="col-11">
            <div className="form-check">
              <br />
              <input className="form-check-input" type="checkbox" name="has_special_conditions" checked={props.userInput.has_special_conditions} onChange={props.handleUserInput} />
              <label className="form-check-label">
                {t('Speciální podmínky domluvené se zástupcem Bola')}
              </label>
            </div>
          </div>
        </div>
        
        {props.userInput.has_special_conditions && (
          <div className="row mb-3">
            <div className="col-11">
              <label>{t('Uveďte jméno zástupce a domluvené podmínky')} <span className="text-danger">*</span></label>
              <textarea maxLength="500" className="form-control" rows="2" placeholder="..." name="special_conditions" value={props.userInput.special_conditions} onChange={props.handleUserInput}></textarea>
            </div>
          </div>
        )}

        {error === 'duvod_missing' && !props.userInput.duvod_vraceni_detail &&
          <div className="alert alert-danger mt-3" role="alert">
            {t('Prosím popište důvod reklamace zboží.')}   
          </div>
        }

        {error === 'special_conditions_missing' && props.userInput.has_special_conditions && !props.userInput.special_conditions &&
          <div className="alert alert-danger mt-3" role="alert">
            {t('Prosím uveďte jméno zástupce a domluvené podmínky.')}   
          </div>
        }

        {error === 'ucet_missing' && !props.userInput.cislo_uctu_user &&
          <div className="alert alert-danger mt-3" role="alert">
            {t('Prosím zadejte číslo účtu.')}  
          </div>
        }

        {error === 'iban_missing' && !props.userInput.iban_user &&
          <div className="alert alert-danger mt-3" role="alert">
            {t('Prosím zadejte IBAN číslo účtu.')}   
          </div>
        }

        {error === 'wrong_account_number' && !props.userInput.no_account_number &&
          <div className="alert alert-danger mt-3" role="alert">
            {t('Nespravné číslo účtu. Zadejte jej prosím ve formátu xx-xxxxxxxxx/xxxx a zkontrolujte na překlepy.')}   
          </div>
        }

        <div className="row mt-3">
          <div className="col-12">
            <button className="btn btn-secondary me-3" onClick={() => props.link('product_selector')}>{t('Zpět')}</button>
            <button className="btn btn-primary" onClick={() => checkAndMove()}>{t('Pokračovat')}</button>
          </div>
        </div>

      </div>
    </div>
  )
}